import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { useLocation } from "react-router-dom";

import img1 from "../../../assets/img/snow0.jpeg";
import img2 from "../../../assets/img/snow1.jpeg";
import "../styleAllServices.css";

export default function SnowManagement() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [keywords, setKeywords] = useState([]);

  //Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca/services/snow-management`;
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", canonicalUrl);

    const newTitle = "Snow Management | BUR-HAN Garden & Lawn Care";
    setPageTitle(newTitle);
    document.title = newTitle;

    const newDescription =
      "Enhance the beauty and function of your outdoor space with expert snow management services from BUR-HAN. Our comprehensive solutions ensure safe and accessible sites during winter months, backed by a commitment to reliability, live monitoring, and timely billing.";
    setPageDescription(newDescription);

    const newKeywords = [
      "snow management, snow plowing, snow shovelling, snow blowing, salting, pre-treatment, post-treatment, 24/7 response, seasonal contracts, snow monitoring, insured, bonded, warehousing, residential, commercial, Vancouver, Burnaby, North Vancouver, West Vancouver, services, snow, winter",
    ];
    setKeywords(newKeywords);
    document.keyword = newKeywords;

    // Update og:title meta tag
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", newTitle);

    // Update og:description meta tag
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", newDescription);

    // Update description meta tag
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", newDescription);

    // Update keywords meta tag
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", newKeywords.join(","));
  }, [location.pathname, setPageTitle, setPageDescription]);

  return (
    <div className="wrapper">
      <Header />

      <div className="serviceItemsHeader">
        <h1 data-aos="zoom-in">Snow Management</h1>
      </div>
      <div className="serviceItemsMain">
        <h2 data-aos="fade-down">
          "Enhancing the beauty and function of your outdoor space with expert
          snow management."
        </h2>
        <hr />

        <h2
          className="section-header text-center pb-4 mb-5"
          data-aos="fade-right"
        >
          Snow Management Services
        </h2>

        <div className="container mb-5 pb-5">
          <div className="row">
            <div className="hex-cols" data-aos="zoom-in">
              <div className="hexagon-menu">
                <div className="hexagon-item">
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content ">
                    <span className="hex-content-inner ">
                      <span className="icon">
                        <i class="fa-solid fa-snowplow"></i>
                      </span>
                      <span className="title">Snow Plowing</span>
                    </span>

                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content ">
                    <span className="hex-content-inner ">
                      <span className="icon">
                        <i class="fa-solid fa-person-digging"></i>
                      </span>
                      <span className="title">
                        Snow Shovelling & Snow Blowing
                      </span>
                    </span>

                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content ">
                    <span className="hex-content-inner ">
                      <span className="icon">
                        <i class="fa-solid fa-snowman"></i>
                      </span>
                      <span className="title">
                        {" "}
                        Salting, Pre & Post Treatments
                      </span>
                    </span>

                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content ">
                    <span className="hex-content-inner ">
                      <span className="icon">
                        <i class="fa-regular fa-clock"></i>
                      </span>
                      <span className="title">24/7 Response</span>
                    </span>

                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content ">
                    <span className="hex-content-inner ">
                      <span className="icon">
                        <i class="fa-solid fa-calendar-days"></i>
                      </span>
                      <span className="title">Seasonal Contracts</span>
                    </span>

                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content ">
                    <span className="hex-content-inner ">
                      <span className="icon">
                        <i class="fa-solid fa-snowflake"></i>
                      </span>
                      <span className="title">Snow & Weather Monitoring </span>
                    </span>

                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content ">
                    <span className="hex-content-inner ">
                      <span className="icon">
                        <i class="fa-solid fa-circle-check"></i>
                      </span>
                      <span className="title">Insured for Snow Services</span>
                    </span>

                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content ">
                    <span className="hex-content-inner ">
                      <span className="icon">
                        <i class="fa-solid fa-warehouse"></i>
                      </span>
                      <span className="title">Warehousing/ Commercial</span>
                    </span>

                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item ">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content ">
                    <span className="hex-content-inner ">
                      <span className="icon">
                        <i class="fa-solid fa-house-circle-check"></i>
                      </span>
                      <span className="title">Residential Homes & Strata</span>
                    </span>

                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* -------------- serviceImages SECTION -------------*/}

        {/* -------------- BANNER SECTION -------------*/}

        <div className="banner-section">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <h3 className="heading" data-aos="fade-right">
                  "Empowering Progress, Igniting <span> Results!" </span>
                </h3>
              </div>
              <div
                className="col-lg-6 col-md-12 col-sm-12"
                data-aos="fade-left"
              >
                <p className="text">
                  "Revitalize your outdoor space with our expert landscaping
                  maintenance services, ensuring a thriving and picturesque
                  environment year-round."
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* -------------- about SECTION -------------*/}

        <section className="section-container">
          <div className="image-container px-5" data-aos="fade-right">
            <img src={img1} alt="snow" />
          </div>
          <div className="text-container px-5" data-aos="fade-up">
            <span className="caption d-block mb-2 font-weight-bold">
              Outstanding Services
            </span>
            <h3 className="headingImageText text-uppercase ">
              Snow Management
            </h3>
            <p>
              Reliable Snow Management at your service! BUR-HAN is proud to
              offer full snow management service contracts for the areas of
              Vancouver, Burnaby, North Vancouver & West Vancouver. We're here
              around the clock when winter strikes to ensure the safety and
              accessibility of your site with our friendly and dedicated team.
            </p>
          </div>
        </section>
        <section className="section-container">
          <div className="text-container px-5" data-aos="fade-up">
            <h3 className="headingImageText text-uppercase">
              Why choose BUR-HAN for your
              <br /> snow management needs?
            </h3>
            <ul>
              <li>
                <strong>Live Monitoring:</strong> We keep a close eye on the
                weather so you don't have to! Our crews are ready to dispatch at
                a moments notice.{" "}
              </li>
              <li>
                {" "}
                <strong>Timely Billing:</strong> All invoices are sent out in a
                timely manner and include a copy of the weather networks
                predicted forecast so you have copies of what the weather was
                calling for during each salting/snow clearing service.{" "}
              </li>
              <li>
                {" "}
                <strong> Insured & Bonded:</strong> Feel safe with us! We are
                fully ensured and bonded for your protection.{" "}
              </li>
            </ul>
          </div>
          <div className="image-container px-5" data-aos="fade-left">
            <img src={img2} alt="snow" />
          </div>
        </section>

        {/* -------------- END OF  -------------*/}

        <div className="secondBanner">
          <div className="container" data-aos="fade-down">
            <div className="row">
              <div className="col-md-6 ">
                <h3>Ready to elevate your outdoor experience?</h3>
                <p>
                  Fill out our Get a Quote page, and let our expert landscaping
                  team bring your vision to life with a personalized estimate
                  tailored to your unique preferences and needs.
                </p>
              </div>
              <div className="col-md-6 ">
                <div className="call-to-action text-right">
                  <a href="/contact-us" className="get-a-quote">
                    Get A Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}
