import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import "../styleAllBlogs.css";
import img from "../../../assets/img/image6.jpg";
import img1 from "../../../assets/img/blog-seasonalguide.jpg";
import img2 from "../../../assets/img/blog-seasonalguide1.jpg";
import img3 from "../../../assets/img/blog-seasonalguide2.jpg";

export default function SeasonalGuide() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [pageType, setPageType] = useState("");

  //Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca/blog/garden-maintenance-guide`;
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", canonicalUrl);

    const newTitle = "Garden Maintenance Guide - SPRING Blog";
    setPageTitle(newTitle);
    document.title = newTitle;

    const newDescription =
      "Discover essential spring landscape maintenance tips in our comprehensive seasonal garden maintenance guide. From cleanup and pruning to spring planting and fertilization, learn how to maintain a vibrant and healthy landscape in Vancouver and the Lower Mainland.";
    setPageDescription(newDescription);

    const newType = "article";
    setPageType(newType);

    // Update og:title meta tag
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", newTitle);

    // Update og:description meta tag
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", newDescription);

    // Update description meta tag
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", newDescription);

    // Update type meta tag
    document
      .querySelector('meta[property="og:type"]')
      .setAttribute("content", newType);

    // Update keywords meta tag
    const newKeywords = [
      "spring landscape maintenance,seasonal garden tips,spring gardening tips, vancouver garden care, lower Mainland landscape, garden cleanup,pruning tips, spring planting guide, fertilization tips, healthy landscape, garden maintenance vancouver,landscape maintenance guide, spring garden preparation,lawn care vancouver, seasonal garden maintenance",
    ];
    setKeywords(newKeywords);
  }, [location.pathname, setPageTitle, setPageDescription]);

  return (
    <div className="wrapper">
      <Header />
      <div className="beforeSection"></div>
      <div className="sectionBlogpost seasonalmaintenance">
        <a href="/blog" className="backPosts">
          <i className="bi bi-arrow-left"></i>
          Back
        </a>
        <article>
          <header>
            <h2>
              <div
                className="nth-line-1"
                data-aos="fade-up"
                style={{ fontSize: "7.4vw" }}
              >
                Garden Maintenance Guide - SPRING
              </div>
              <div className="nth-line-2" data-aos="fade-up">
                for a Healthy and Beautiful Landscape
              </div>
            </h2>
          </header>
          <hr />
          <section data-aos="fade-right">
            <p>
              Maintaining a vibrant and healthy landscape in Vancouver and the
              lower Mainland requires ongoing care and attention throughout the
              year. To help you keep your outdoor space in top shape, we've put
              together this comprehensive seasonal maintenance guide. From
              spring planting to winter preparation, we'll walk you through the
              essential tasks to ensure your landscape thrives in every season.{" "}
            </p>
            <img src={img} alt="spring garden" className="imgblog" />
            <p>
              <span>Spring:</span> <br />
              As the weather warms up and nature comes back to life, spring is
              the perfect time to kickstart your landscape maintenance routine.
              Here's what you should focus on:
            </p>
            <p>
              <span> Clean Up: </span>
              Over winter and because of storms you may have a lot of debris in
              your yard. Start by clearing away any debris that has accumulated
              over the winter, such as fallen leaves, branches, and dead plant
              material. In Vancouver and the Lower Mainland we have a high
              density of conifers. Conifer needles are acidic, when left in a
              garden bed they have the potential to change the pH of the soil
              and can ultimately affect the soil's fertility.
            </p>
            <p>
              <span> Pruning: </span>
              Pruning of shrubs, trees and perennials can occur through the
              different seasons of the year depending on the plant. For
              Vancouver and the Lower Mainland pruning certain shrubs in late
              winter or early spring, just before new growth begins, is ideal
              for encouraging healthy development, maintaining shape and
              ensuring vigorous flowering or fruiting. Examples of these early
              spring shrubs include summer blooming spirea, dogwood (cornus),
              butterfly bush, roses, red twig dogwood, hydrangeas, beautyberry,
              and hardy fuchsias. These pruning times are aimed at stimulating
              healthy growth, improving flowering and fruiting, and maintaining
              the desired shape and size of the shrubs. It's also a good
              opportunity to remove any dead, damaged, or diseased wood, which
              helps prevent the spread of pests and disease. It is important to
              identify the shrub, tree or perennial you plan to prune, to ensure
              the correct seasonal timing.
              If you need any assistance with a garden cleanup, pruning or planting, see our <a href="/services/softscape-installs-maintenance" style={{color: "var(--yellow-color)",textDecoration: "none"}}>Softscape Installs/Maintenance</a> page for more information on the types of services we can help you with!
            </p>
            <p>
              <span> Spring Planting: </span>
              Plant new trees, shrubs, and flowers to add color, vibrancy and a
              variety of textures to your landscape. Choose plants that are
              well-suited to our climate zone. Other conditions to consider are
              light levels of the area you are planting in, soil structure and
              moisture.
            </p>
            <p>
              <span>Fertilization and Limestone: </span>
              Fertilization of your garden and lawns is an important
              consideration. Lawns in Vancouver and the surrounding areas tend
              to be more acidic due to the amount of rainfall we receive. Other
              factors contributing to the acidity of. the soil are conifer
              droppings. In general you can safely apply 20kg of prilled lime to
              every 2,000 square feet of lawn. Remember that lime changes the pH
              of a lawn very slowly and as a result can generally be applied
              yearly. Lawns prefer a pH range between 5.5 and 7. A soil's pH can
              be tested if you would like to know the pH you are starting with.
              Simple hand held tools can provide basic information or samples
              can be sent to a soil testing lab. If your soil is not in the
              correct pH range, nutrients from your fertilizer may not be
              available for uptake by the grass plant. When fertilizing your
              lawn you should know the exact size of your lawn as the nutrients
              in fertilizer are displayed as a percentage of weight on the bag.
              Typically in spring you will want to apply 2 lbs of nitrogen for
              every 1,000 sq ft. The other nutrients like potassium and
              phosphorus which are labeled on the bag will be chosen based on
              the time of the year, deficiencies and if you are seeding.
            </p>
            <p>For more information on Fertilizer and how to apply, see our Blog: <a href="/blog/the-essential-guide-to-lawn-fertilization" style={{color: "var(--yellow-color)",textDecoration: "none"}}>The Essential Guide to Lawn Fertilization</a>.</p>
          </section>
        </article>
        <div className="secondBanner">
          <div className="container" data-aos="fade-down">
            <div className="row">
              <div className="col-md-6">
                <h3>Ready to elevate your outdoor experience?</h3>
                <p>
                  Fill out our contact page, and let our expert landscaping
                  team bring your vision to life with a personalized estimate
                  tailored to your unique preferences and needs.
                </p>
              </div>
              <div className="col-md-6 ">
                <div className="call-to-action text-right">
                  <a href="/contact-us" className="get-a-quote">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
