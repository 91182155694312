import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import img1 from "../../../assets/img/softscape1.jpg";
import img2 from "../../../assets/img/softscape2.jpg";

import "../styleAllServices.css";

export default function SoftscapeInstallsMaintenance() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [keywords, setKeywords] = useState([]);

  //Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca/services/softscape-installs-maintenance`;
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", canonicalUrl);

    const newTitle =
      "Softscape Installs/Maintenance | BUR-HAN Garden & Lawn Care";
    setPageTitle(newTitle);
    document.title = newTitle;

    const newDescription =
      "Transform your outdoor space with expert softscape installs and maintenance services. Enhance beauty and function with our landscaping solutions. Contact us for personalized quotes.";
    setPageDescription(newDescription);

    const newKeywords = [
      "softscape installs, softscape maintenance, landscaping services, outdoor space, landscaping solutions, garden care, plant selection, soil analysis, material installation, pest management, disease control, garden reclamation, native plant selection, landscape design, landscape maintenance",
    ];
    setKeywords(newKeywords);
    document.keyword = newKeywords;

    // Update og:title meta tag
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", newTitle);

    // Update og:description meta tag
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", newDescription);

    // Update description meta tag
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", newDescription);

    // Update keywords meta tag
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", newKeywords.join(","));
  }, [location.pathname, setPageTitle, setPageDescription]);

  return (
    <div className="wrapper">
      <Header />

      <div className="serviceItemsHeader">
        <h1 data-aos="zoom-in">Softscape Installs/Maintenance</h1>
      </div>
      <div className="serviceItemsMain">
        <h2 data-aos="fade-down">
          "Enhancing the beauty and function of your outdoor space with expert
          softscape installs/maintenance."
        </h2>
        <hr />

        <h2
          className="section-header text-center pb-4 mb-5"
          data-aos="fade-right"
        >
          Softscape Installs/Maintenance
        </h2>

        <div className="container mb-5 pb-5">
          <div className="row">
            <div className="hex-cols" data-aos="zoom-in">
              <div className="hexagon-menu">
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-brands fa-unity"></i>
                      </span>
                      <span className="title">2D & 3D Design </span>
                    </span>

                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>

                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-chart-line"></i>
                      </span>
                      <span className="title">Soil Analysis & Amendment</span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>

                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-arrow-up-right-from-square"></i>
                      </span>
                      <span className="title">
                        Material installation Mulch/Play-Chip/Rock/Stone{" "}
                      </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>

                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-leaf"></i>
                      </span>
                      <span className="title">
                        Plant Selection <br /> & Installation
                        Annuals/Perennials/
                        <br />
                        Trees/Shrubs/Hedges
                      </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>

                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-tenge-sign"></i>
                      </span>
                      <span className="title">
                        Edging Installations Garden Beds/Paths/Lawns
                      </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>

                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-sun-plant-wilt"></i>
                      </span>
                      <span className="title">
                        Garden Care and Maintenance{" "}
                      </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>

                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content" href="/services/residential-lawn-care/european-chafer-beetle-control">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-bugs"></i>
                      </span>
                      <span className="title">
                        Pest and Disease Management{" "}
                      </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>

                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-square-check"></i>
                      </span>
                      <span className="title">
                        Garden Reclamation Cleanups/Overgrown/ Blackberry+Bamboo
                        Control{" "}
                      </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-square-share-nodes"></i>
                      </span>
                      <span className="title">
                        Restoration Gardens/Native Plant Selection/Bi-law
                        Complaints{" "}
                      </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* -------------- serviceImages SECTION -------------*/}

        {/* -------------- BANNER SECTION -------------*/}

        <div className="banner-section">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <h3 className="heading" data-aos="fade-right">
                  "Empowering Progress, Igniting <span> Results!" </span>
                </h3>
              </div>
              <div
                className="col-lg-6 col-md-12 col-sm-12"
                data-aos="fade-left"
              >
                <p className="text">
                  "Revitalize your outdoor space with our expert landscaping
                  maintenance services, ensuring a thriving and picturesque
                  environment year-round."
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* -------------- about SECTION -------------*/}

        <section className="section-container">
          <div className="image-container px-5" data-aos="fade-right">
            <img src={img1} alt="softscape" />
          </div>
          <div className="text-container px-5" data-aos="fade-up">
            <span className="caption d-block mb-2 font-weight-bold">
              Outstanding Services
            </span>
            <h3 className="headingImageText text-uppercase ">
              Softscape Installs/Maintenance
            </h3>
            <p>
              At BUR-HAN, we transform outdoor spaces with precision and care.
              Our softscape services encompass expert pruning & shearing,
              soil/mulch/decorative stone installations, and innovative design,
              creating a harmonious and vibrant environment for your home or
              business. We craft landscapes that not only captivate with their
              beauty but also promote environmental well-being, with a focus on
              water-efficient, native, unique choices, our designs strike
              harmony between aesthetics and sustainability. Let us make your
              landscape dreams a reality! Elevate your landscape with our expert
              touch.{" "}
            </p>
          </div>
        </section>
        <section className="section-container">
          <div className="text-container px-5" data-aos="fade-up">
            <h3 className="headingImageText text-uppercase">
              Why choose BUR-HAN for your
              <br /> softscape installs / maintenance needs:
            </h3>
            <ul>
              <li>
                <strong>Personalized Quotes: </strong> We understand that your
                gardening, pruning, and design desires are unique to you, and we
                look forward to making that a reality.
              </li>
              <li>
                {" "}
                <strong>Sustainability: </strong> Organic gardening services
                that avoid herbicides - we promote sustainable weeding practices
                including mulch installation and horticultural vinegar.
              </li>
              <li>
                {" "}
                <strong> Plant Health:</strong> Our team consists of passionate
                professionals with extensive plant identification knowledge, and
                proper pruning and planting practices.{" "}
              </li>
              <li>
                <strong>Designs:</strong> Dedicated landscape designers that can
                shape your space with colour, texture, native plants,
                water-efficient selections, and seasonal interest.
              </li>
              <li>
                <strong>Investment:</strong> Increase the value of your property
                with our high-quality aesthetic.{" "}
              </li>
              <li>
                <strong>Save Time:</strong> Spend more time with your family and
                leave the hard work to us.
              </li>
              <li>
                <strong>No Equipment to Maintain:</strong> We use the latest
                equipment so you don’t have to worry about maintaining your own.{" "}
              </li>
            </ul>
          </div>
          <div className="image-container px-5" data-aos="fade-left">
            <img src={img2} alt="softscape" />
          </div>
        </section>

        {/* -------------- END OF  -------------*/}

        <div className="secondBanner">
          <div className="container" data-aos="fade-down">
            <div className="row">
              <div className="col-md-6 ">
                <h3>Ready to elevate your outdoor experience?</h3>
                <p>
                  Fill out our Get a Quote page, and let our expert landscaping
                  team bring your vision to life with a personalized estimate
                  tailored to your unique preferences and needs.
                </p>
              </div>
              <div className="col-md-6 ">
                <div className="call-to-action text-right">
                  <a href="/contact-us" className="get-a-quote">
                    Get A Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}
