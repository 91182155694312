import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import "../styleAllProjects.css";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photosRetainingWall } from "../../../script";
import beesFlying from "../../../assets/img/load.gif";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function RetainingWall() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [keywords, setKeywords] = useState([]);

  //Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca/our-projects/retaining-walls`;
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", canonicalUrl);

    const newTitle = "Retaining Walls Project | BUR-HAN Garden & Lawn Care";
    setPageTitle(newTitle);
    document.title = newTitle;

    const newDescription =
      "Discover our upcoming projects related to retaining walls and learn how our expert landscaping team can create stunning and functional retaining wall designs to enhance your outdoor space. Stay tuned for high-quality retaining wall services tailored to your specific needs and preferences.";
    setPageDescription(newDescription);

    const newKeywords = [
      "retaining wall, landscaping, outdoor projects, retaining wall design, retaining wall services, landscaping services, outdoor design, landscape design",
    ];
    setKeywords(newKeywords);
    document.keyword = newKeywords;

    // Update og:title meta tag
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", newTitle);

    // Update og:description meta tag
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", newDescription);

    // Update description meta tag
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", newDescription);

    // Update keywords meta tag
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", newKeywords.join(","));
  }, [location.pathname, setPageTitle, setPageDescription]);

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div className="wrapper">
      <Header />

      <div className="projectsGalleryHeader">
        <h1 data-aos="zoom-in"> Retaining wall</h1>
      </div>
      <div className="projectsGalleryMain" data-aos="fade-up">
        {/* <h2>COMING SOON!</h2>
        <a href="/our-projects" className="to-top">
          Back to projects
        </a>

        <img
          src={beesFlying}
          style={{
            margin: "auto",
            display: "block",
          }}
          className="beeGif"
          alt="honeycomb"
        /> */}
         <h2 className="text-center"> <a href="/hardscape-installs-maintenance"> 
        Click here to learn more about our services   </a> </h2>
        <hr />
        <a href="/our-projects" className="to-top">Back to projects</a>

        <div className="gallery">
         <Gallery photos={photosRetainingWall} onClick={openLightbox}    
/>
      <ModalGateway >
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel 
 currentIndex={currentImage}
 views ={photosRetainingWall.map(x => ({
    ...x,
    srcset: x.srcSet,
    caption: x.title,
    alt: x.alt,
    
 }))}
/>

       

          </Modal>
        ) : null}
      </ModalGateway> 
      

       

      </div>
        <Footer />
      </div>
    </div>
  );
}
