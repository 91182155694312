import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { useLocation } from "react-router-dom";
import "../styleAllServices.css";

export default function ServicesAndLocations() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [keywords, setKeywords] = useState([]);

  //Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca/services/services-and-locations`;
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", canonicalUrl);
      
    const newTitle = "Services And Locations | BUR-HAN Garden & Lawn Care";
    setPageTitle(newTitle);
    document.title = newTitle;

    const newDescription =
      "Enhance the beauty and functionality of your outdoor space with expert services from BUR-HAN. Our comprehensive solutions ensure a thriving and picturesque environment year-round, backed by a commitment to personalized service and eco-friendly practices.";
    setPageDescription(newDescription);

    const newKeywords = [
      "landscape maintenance, lawn care, hardscape, softscape, design, snow management, residential, commercial, Vancouver, Burnaby, North Vancouver, West Vancouver, Coquitlam, New Westminster, Surrey, Port Moody, Delta, White Rock, services, locations",
    ];
    setKeywords(newKeywords);
    document.keyword = newKeywords;

    // Update og:title meta tag
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", newTitle);

    // Update og:description meta tag
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", newDescription);

    // Update description meta tag
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", newDescription);

    // Update keywords meta tag
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", newKeywords.join(","));
  }, [location.pathname, setPageTitle, setPageDescription]);

  return (
    <div className="wrapper">
      <Header />

      <div className="serviceItemsHeader">
        <h1 data-aos="zoom-in">Services And Locations</h1>
      </div>
      <div className="serviceItemsMain">
        <h2 data-aos="fade-down">
          "Enhancing the beauty and function of your outdoor space with expert
          landscape maintenance."
        </h2>
        <hr />

        <h2
          className="section-header text-center pb-4 mb-5"
          data-aos="fade-right"
        >
          Services And Locations
        </h2>

        <div className="container mb-5 pb-5">
          <div className="row">
            <div className="hex-cols" data-aos="zoom-in">
              <div className="hexagon-menu">
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-layer-group"></i>
                      </span>
                      <span className="title">Landscape Maintenance</span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-house-circle-check"></i>
                      </span>
                      <span className="title">Residential Lawn Care</span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-trowel-bricks"></i>
                      </span>
                      <span className="title">
                        Hardscape Installs/Maintenance
                      </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-tree"></i>
                      </span>
                      <span className="title">
                        Softscape Installs/Maintenance
                      </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-brands fa-unity"></i>
                      </span>
                      <span className="title">Design And Modelling</span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>

                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-snowflake"></i>
                      </span>
                      <span className="title">
                        {" "}
                        Snow Management Areas: Vancouver/North Van/West
                        Van/Burnaby
                      </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-regular fa-snowflake"></i>
                      </span>
                      <span className="title">Snow Management</span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-building"></i>
                      </span>
                      <span className="title">
                        Residential: Vancouver/Burnaby
                        <br />
                        North Van/West Van
                        <br />
                        Coquitlam/New West
                      </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-city"></i>
                      </span>
                      <span className="title">
                        Commercial: Vancouver/Richmond
                        <br />
                        North Van/Burnaby
                        <br />
                        Coquitlam/New West <br />
                        Surrey/Port Moody
                        <br /> Delta/White Rock
                        <br /> West Van
                      </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* -------------- serviceImages SECTION -------------*/}

        {/* -------------- END OF  -------------*/}

        <div className="secondBanner">
          <div className="container" data-aos="fade-down">
            <div className="row">
              <div className="col-md-6 ">
                <h3>Ready to elevate your outdoor experience?</h3>
                <p>
                  Fill out our Get a Quote page, and let our expert landscaping
                  team bring your vision to life with a personalized estimate
                  tailored to your unique preferences and needs.
                </p>
              </div>
              <div className="col-md-6 ">
                <div className="call-to-action text-right">
                  <a href="/contact-us" className="get-a-quote">
                    Get A Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}
