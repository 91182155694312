import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import { useLocation } from "react-router-dom";
import Footer from "../../../components/Footer";
import img1 from "../../../assets/img/hardscape1.jpg";
import img2 from "../../../assets/img/hardscape2.jpeg";
import "../styleAllServices.css";

export default function HardscapeInstallsMaintenance() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [keywords, setKeywords] = useState([]);

  //Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca/services/hardscape-installs-maintenance`;
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", canonicalUrl);

    const newTitle =
      "Hardscape Installs/Maintenance | BUR-HAN Garden & Lawn Care";
    setPageTitle(newTitle);
    document.title = newTitle;

    const newDescription =
      "Enhance the beauty and functionality of your outdoor space with expert hardscape installations and maintenance services. Elevate your outdoor sanctuary with premium hardscape solutions, personalized quotes, durability, water management, design modelling, and investment opportunities. Get a personalized estimate today!";
    setPageDescription(newDescription);

    const newKeywords = [
      "hardscape,hardscaping north vancouver, installations, maintenance, landscaping, outdoor space, outdoor sanctuary, hardscape solutions, personalized quotes, durability, water management, design modelling, investment, landscaping services, landscape design, outdoor experience, landscaping team",
    ];
    setKeywords(newKeywords);
    document.keyword = newKeywords;

    // Update og:title meta tag
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", newTitle);

    // Update og:description meta tag
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", newDescription);

    // Update description meta tag
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", newDescription);

    // Update keywords meta tag
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", newKeywords.join(","));
  }, [location.pathname, setPageTitle, setPageDescription]);
  return (
    <div className="wrapper">
      <Header />
      <div className="serviceItemsHeader">
        <h1 data-aos="zoom-in">Hardscape Installs/Maintenance</h1>
      </div>
      <div className="serviceItemsMain">
        <h2 data-aos="fade-down">
          "Enhancing the beauty and function of your outdoor space with expert
          hardscape installs/maintenance."
        </h2>
        <hr />
        <h2
          className="section-header text-center pb-4 mb-5"
          data-aos="fade-right"
        >
          Hardscape Installs/Maintenance Services
        </h2>
        <div className="container mb-5 pb-5">
          <div className="row">
            <div className="hex-cols" data-aos="zoom-in">
              <div className="hexagon-menu">
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-ellipsis"></i>
                      </span>
                      <span className="title">
                        Paver/slab patios and walkways
                      </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item  nohover">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-cubes-stacked"></i>
                      </span>
                      <span className="title">Modular block walls</span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-droplet"></i>
                      </span>
                      <span className="title">
                        New Irrigation Installations with New Landscapes
                      </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-water"></i>
                      </span>
                      <span className="title">
                        Grading and drainage solutions
                      </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-angles-up"></i>
                      </span>
                      <span className="title">
                        Gravel/river rock installation
                      </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-tractor"></i>
                      </span>
                      <span className="title">Excavation</span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-bolt-lightning"></i>
                      </span>
                      <span className="title">Landscape lighting</span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-tarp-droplet"></i>
                      </span>
                      <span className="title">Water features</span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-object-group"></i>
                      </span>
                      <span className="title">
                        Paver Maintenance Cleaning/Jointing/
                        <br />
                        Levelling/Repair{" "}
                      </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* -------------- serviceImages SECTION -------------*/}

        {/* -------------- BANNER SECTION -------------*/}
        <div className="banner-section">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <h3 className="heading" data-aos="fade-right">
                  "Empowering Progress, Igniting <span> Results!" </span>
                </h3>
              </div>
              <div
                className="col-lg-6 col-md-12 col-sm-12"
                data-aos="fade-left"
              >
                <p className="text">
                  "Revitalize your outdoor space with our expert landscaping
                  maintenance services, ensuring a thriving and picturesque
                  environment year-round."
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* -------------- about SECTION -------------*/}
        <section className="section-container">
          <div className="image-container px-5" data-aos="fade-right">
            <img src={img1} alt="hardscape maintenance" />
          </div>
          <div className="text-container px-5" data-aos="fade-up">
            <span className="caption d-block mb-2 font-weight-bold">
              Outstanding Services
            </span>
            <h3 className="headingImageText text-uppercase ">
              Hardscape Installs/Maintenance
            </h3>
            <p>
              Elevate your outdoor sanctuary with BUR-HAN's premium Hardscape
              Installation and Maintenance services. We are continually updating
              our standards and techniques to ensure we remain at the forefront
              of our industry. This means we offer you maintenance services that
              not only safeguard your investment but also benefit the
              environment we all share. Have confidence knowing that all of our
              installers receive ongoing education to keep up with the latest
              standards and procedures for all things hardscape. Our team
              understands the importance that comes with maintaining your
              existing hardscape, and the best practices to keep your space
              looking as good as the day it was installed. Any project you have
              in mind, we can help you create and infuse your landscape with
              durability and style. Trust BUR-HAN to keep your hardscapes in
              peak condition, fostering the beauty and functionality that your
              space deserves.{" "}
            </p>
          </div>
        </section>
        <section className="section-container">
          <div class="text-container px-5" data-aos="fade-up">
            <h3 className="headingImageText text-uppercase">
              Why choose BUR-HAN for your
              <br /> hardscape install and maintenance needs?
            </h3>
            <ul>
              <li>
                <strong>Personalized Quotes: </strong> We understand that your
                hardscape installs, maintenance, and design desires are unique
                to you, and we look forward to making that a reality.
              </li>
              <li>
                {" "}
                <strong>Durability: </strong> Our hardscape installations are
                expertly crafted for long-lasting performance.
              </li>
              <li>
                {" "}
                <strong> Water Management: </strong> Rest easy knowing your new
                walls and patios were built to properly handle your sites
                drainage and water requirements.
              </li>
              <li>
                <strong>Design and Modelling:</strong> Take out any guesswork
                when our team fully models your project before it even starts.
              </li>
              <li>
                <strong>Investment:</strong> Increase the value of your property
                by expanding finished and functional space.
              </li>
            </ul>
          </div>
          <div className="image-container px-5" data-aos="fade-left">
            <img src={img2} alt="hardscape maintenance" />
          </div>
        </section>
        <div className="secondBanner">
          <div className="container" data-aos="fade-down">
            <div className="row">
              <div className="col-md-6 ">
                <h3>Ready to elevate your outdoor experience?</h3>
                <p>
                  Fill out our Get a Quote page, and let our expert landscaping
                  team bring your vision to life with a personalized estimate
                  tailored to your unique preferences and needs.{" "}
                </p>
              </div>
              <div className="col-md-6 ">
                <div className="call-to-action text-right">
                  <a href="/contact-us" className="get-a-quote">
                    Get A Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}
