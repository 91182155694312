import React, { useEffect, useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import { useLocation } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./style.css";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  bgHomeImages,
  testiMonials,
  servicesOption,
  TestiMonialsDetails,
  options,
  selectCity,
  hearaboutusOption,
  styles,
} from "../../script";
import "normalize.css/normalize.css";
import one from "../../assets/img/one.svg";
import two from "../../assets/img/two.svg";
import three from "../../assets/img/three.svg";
import four from "../../assets/img/four.svg";
// Count up numbers
import CountUp from "react-countup";
//Owl Carousel Libraries and Module
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import c1 from "../../assets/img/partners/badge2.png";
import c2 from "../../assets/img/partners/badge1.png";
import c3 from "../../assets/img/partners/badge3.png";
import c4 from "../../assets/img/partners/badge4.png";
import c5 from "../../assets/img/partners/badge5.png";
import c6 from "../../assets/img/partners/badge6.png";
import c7 from "../../assets/img/partners/badge7.png";
import c8 from "../../assets/img/partners/badge8.png";
import beelogo from "../../assets/img/bee-circle-blue.png";
import burhanTeam from "../../assets/img/aboutImg.png";
import BHanniversary from '../../assets/img/BurHan29thAnniversary.png';


const animatedComponents = makeAnimated();

export default function Home() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const history = useNavigate(); // Get the history object from React Router
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailInput = form.current["email"].value;
    if (!emailRegex.test(emailInput)) {
      alert("Please enter a valid email address.");
      return;
    }

    emailjs
      .sendForm(
        process.env.REACT_APP_TEMPLATE_ID,
        process.env.REACT_APP_SERVICE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          setShowSuccessMessage(true); // Show the popup message

          // // Send event to Google Analytics
          // window.gtag("event", "email_sent", {
          //   event_category: "Email",
          //   event_label: "Email sent successfully",
          // });
          // // Increment a custom metric to track the number of emails sent
          // window.gtag("event", "email_sent_count", {
          //   event_category: "Email",
          //   event_label: "Email sent successfully",
          // });
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  };

  const handleReload = () => {
    setShowSuccessMessage(false); // Hide the popup message
    history("/"); // Navigate to the homepage
    window.location.reload(); // Reload the page
  };


  //Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca`;
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", canonicalUrl);

    const newTitle = "BUR-HAN Garden & Lawn Care | Professional Landscaping Services in Vancouver"
    ;
    setPageTitle(newTitle);
    document.title = newTitle;

    const newDescription =
      "BUR-HAN Garden & Lawn Care | Our services include landscape maintenance, residential lawn care, hardscape installations and maintenance, softscape installations and maintenance, designs and modeling, and snow management in Vancouver, Burnaby, North Vancouver, West Vancouver, Coquitlam, Port Coquitlam, New Westminster, Surrey, Port Moody, Delta, White Rock. Contact us for a consultation today!";
    setPageDescription(newDescription);

    const newKeywords = [
      "residential lawn care, landscaping vancouver,landscaping company vancouver, landscaping companies vancouver, landscaping companies vancouver bc, lawn care, lawn care services, garden care near me,lawn care and maintenance near me, vancouver landscaping company, lawn and care, lawn maintenance near me,lawn service, lawn, residential lawn care, yard and lawn maintenance, lawn and garden maintenance"
    ];
    setKeywords(newKeywords);
    document.keyword = newKeywords;

    // Update og:title meta tag
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", newTitle);

    // Update og:description meta tag
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", newDescription);

    // Update description meta tag
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", newDescription);

    // Update keywords meta tag
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", newKeywords.join(","));
  }, [location.pathname, setPageTitle, setPageDescription]);

  return (
    <div id="home" className="wrapper">
      <h1>BUR-HAN Garden & Lawn Care</h1>
      <Header />

      {/* ----------------- CAROUSEL SLIDER ----------------- */}
      <div className="slider-wrapper">
        {bgHomeImages.map((item, index) => (
          <div
            key={index}
            className="slider-content bg-fade"
            style={{
              background: `url('${item.image}') no-repeat center center`,
            }}
          >

            <img className="beeLogo" src={beelogo} alt="bee logo" />
            <div className="inner">
              <h2 data-aos="fade-up" data-aos-delay="400">
                {item.title}
              </h2>
              {/* <button>{item.button}</button> */}
            </div>
          </div>
        ))}
      </div>
      {/* -------------- END OF CAROUSEL SLIDER -------------*/}

      {/* -----------------  PARTNERS -----------------  */}
      <section id="partners" className="partners mb-5">
        <div className="container-partners" data-aos="zoom-in">
          <div className="row d-flex align-items-center justify-content-center">
            <h2 className="section-header">Awards and Affiliations</h2>
            <div className="col-1-5">
              <img
                src={c1}
                className="img-fluid partners-img partner1"
                alt="reader's choice winner badge"
              />
            </div>
            <div className="col-1-5">
              <img
                src={c2}
                className="img-fluid partners-img partner2"
                alt=""
              />
            </div>
            <div className="col-1-5">
              <img
                src={c3}
                className="img-fluid partners-img partner3"
                alt=""
              />
            </div>
            <div className="col-1-5">
              <img
                src={c4}
                className="img-fluid partners-img partner4"
                alt=""
              />
            </div>
            <div className="col-1-5">
              <img
                src={c5}
                className="img-fluid partners-img partner5"
                alt=""
              />
            </div>
            <div className="col-1-5">
              <img
                src={c6}
                className="img-fluid partners-img partner6"
                alt=""
              />
            </div>
            <div className="col-1-5">
              <img
                src={c7}
                className="img-fluid partners-img partner7"
                alt=""
              />
            </div>
            <div className="col-1-5">
              <img
                src={c8}
                className="img-fluid partners-img partner8"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      {/* -------------- END OF PARTNERS -------------*/}

      {/* -------------- ABOUT -------------- */}
      <section id="about" className="about">
        <h2 className="section-header mb1 nodash">
          Make your landscaping project a true success
        </h2>
        <hr />
        <div className="row writtenContent">
          <div
            className="col-lg-6 order-1 order-lg-2 px-5 "
            data-aos="fade-left"
          >
            <img src={burhanTeam} alt="" className="img-fluid aboutImg" />
          </div>
          <div
            className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content"
            data-aos="fade-right"
          >
            <div className="corner-left">
              <div className="title" data-aos="zoom-in">
                <h3>BUR-HAN GARDEN & LAWN CARE</h3>
              </div>
              <div
                className="aboutSentence "
                data-aos="fade-right"
                data-duration="2000"
              >
                <p>
                  BUR-HAN Garden & Lawn Care has been providing Residential &
                  Commercial landscaping services for over 28 years. It is our
                  goal to provide our clients with the best possible landscaping
                  services in Vancouver. We are passionate about creating
                  outdoor environments that inspire and captivate. With our
                  unwavering commitment to excellence, we go above and beyond to
                  exceed our clients expectations. From the initial consultation
                  to the final touches, our experienced team works closely with
                  you, ensuring that every detail aligns with your values and
                  preferences. With our comprehensive range of services,
                  including landscape design, installation, and maintenance, we
                  have the expertise to handle projects of any size and
                  complexity. Whether you envision a lush garden, show stopping
                  hardscape, or a sustainable landscape design, our dedicated
                  team of professionals are here to bring your dreams to life.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* -------------- END OF ABOUT -------------- */}

      {/* -------------- COUNTS -------------- */}
      <section id="counts" className="counts">
        <div className="container" data-aos="fade-up">
          <h2
            className="section-header whiteDash text-white"
            data-aos="zoom-in"
          >
            What we have achieved so far
          </h2>
        </div>
        <div className="row">
          <div
            className="col-3 col-md-3 col-sm-3 d-md-flex align-items-md-stretch"
            data-aos="zoom-in"
          >
            <div className="count-box">
              <i className="bi bi-server"></i>
              <CountUp start={0} end={28} duration={3} enableScrollSpy={true} />
              <p>Years of Service</p>
            </div>
          </div>
          <div
            className="col-3 col-md-3 col-sm-3 d-md-flex align-items-md-stretch"
            data-aos="zoom-in"
          >
            <div className="count-box">
              <i className="bi bi-emoji-smile"></i>
              <CountUp
                start={0}
                end={1030}
                duration={3}
                enableScrollSpy={true}
              />
              <p>Clients </p>
            </div>
          </div>
          <div
            className="col-3 col-md-3 col-sm-3 d-md-flex align-items-md-stretch"
            data-aos="zoom-in"
          >
            <div className="count-box">
              <i className="bi bi-person"></i>
              <CountUp
                start={0}
                end={60}
                suffix="+"
                duration={3}
                enableScrollSpy={true}
              />
              <p>Team Members</p>
            </div>
          </div>
          <div
            className="col-3 col-md-3 col-sm-3 d-md-flex align-items-md-stretch"
            data-aos="zoom-in"
          >
            <div className="count-box">
              <i className="bi bi-reception-4"></i>
              <CountUp
                start={0}
                end={400}
                duration={3}
                enableScrollSpy={true}
              />
              <p>Properties </p>
            </div>
          </div>
        </div>
      </section>
      {/* -------------- END OF COUNTS -------------- */}

      {/* --------------  SERVICES -------------- */}
      <section id="services" className="services section-bg">
        <h2 className="section-header" data-aos="fade-down">
          Our Services
        </h2>
        <p data-aos="zoom-in">
          "We offer excellent services, adapting to a wide range of preferences
          and requirements, ensuring your landscape achieves its utmost
          potential while simultaneously enhancing your property value."
        </p>
        <div className="row">
          <div
            className="col-lg-4 md-3 col-sm-6 col-xs-12"
            data-aos="fade-right"
          >
            <a href="/services/landscape-maintenance">
              <div className="servicesCard">
                <div className="cover item-a">
                  <h3>Landscape Maintenance</h3>
                  <div className="card-back">
                    <ul>
                      <li>Lawn Maintenance</li>
                      <li>Garden Maintenance</li>
                      <li> Water bag installation and filling</li>
                      <li>Irrigation Management</li>
                      <li>Tree staking</li>
                      <li>Pest and Disease Control</li>
                      <li>Tree and Shrub care</li>
                      <li>Seasonal Clean-ups</li>
                      <li>Landscape Enhancement</li>
                    </ul>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div
            className="col-lg-4 md-3 col-sm-6 col-xs-12"
            data-aos="fade-down"
          >
            <a href="/services/hardscape-installs-maintenance">
              <div className="servicesCard">
                <div className="cover item-b">
                  <h3>Hardscape Installs/Maintenance</h3>
                  <div className="card-back">
                    <ul>
                      <li>Paver/slab patios and walkways</li>
                      <li>Modular block walls</li>
                      <li>New Irrigation Installations with New Landscapes</li>
                      <li>Grading and drainage solutions</li>
                      <li>Gravel/river rock installation</li>
                      <li>Excavation</li>
                      <li>Landscape Lighting</li>
                      <li>Water features</li>
                      <li>
                        Paver Maintenance Cleaning/Jointing/Levelling/Repair
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div
            className="col-lg-4 md-3 col-sm-6 col-xs-12"
            data-aos="fade-left"
          >
            <a href="/services/design-and-modelling">
              <div className="servicesCard">
                <div className="cover item-c">
                  <h3>Designs and Modelling</h3>
                  <div className="card-back">
                    <ul>
                      <li>Consultation & Site Analysis </li>
                      <li>2D & 3D Design</li>
                      <li>Plant Selection</li>
                      <li>Hardscaping</li>
                      <li>Landscape Lighting</li>
                      <li>Irrigation</li>
                      <li>Construction Documentation & Project Management</li>
                      <li>Maintenance Plans</li>
                      <li>Permit Assistance</li>
                    </ul>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div
            className="col-lg-4 md-3 col-sm-6 col-xs-12"
            data-aos="fade-right"
          >
            <a href="/services/residential-lawn-care">
              <div className="servicesCard">
                <div className="cover item-d">
                  <h3>Residential Lawn Care</h3>
                  <div className="card-back">
                    <ul>
                      <li>Weekly lawn mowing/edging</li>
                      <li>Fertilizing</li>
                      <li>Liming</li>
                      <li>Aeration</li>
                      <li>Moss Control</li>
                      <li>Organic Weed Control Program</li>
                      <li>Topdressing</li>
                      <li>Overseeding</li>
                      <li>European Chafer Beetle Control </li>
                    </ul>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4 md-3 col-sm-6 col-xs-12" data-aos="fade-up">
            <a href="/services/softscape-installs-maintenance">
              <div className="servicesCard">
                <div className="cover item-e">
                  <h3> Softscape Installs/Maintenance</h3>
                  <div className="card-back">
                    <ul>
                      <li> 2D & 3D Design</li>
                      <li>Soil Analysis & Amendment </li>
                      <li> Material installation Mulch/Play-Chip/Rock/Stone</li>
                      <li>
                        Plant Selection & Installation
                        Annuals/Perennials/Trees/Shrubs/Hedges
                      </li>
                      <li>Edging Installations Garden Beds/Paths/Lawns</li>
                      <li> Garden Care and Maintenance</li>
                      <li>Pest and Disease Management</li>
                      <li>
                        Garden Reclamation Cleanups/Overgrown Blackberry+Bamboo
                        Control
                      </li>
                      <li>
                        Restoration Gardens/Native Plant Selection/Bi-law
                        Complaints
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div
            className="col-lg-4 md-3 col-sm-6 col-xs-12"
            data-aos="fade-left"
          >
            <a href="/services/snow-management">
              <div className="servicesCard">
                <div className="cover item-f">
                  <h3>Snow Management</h3>
                  <div className="card-back">
                    <ul>
                      <li>Snow Plowing</li>
                      <li>Snow Shovelling & Snow Blowing</li>
                      <li>Salting, Pre & Post Treatments</li>
                      <li>24/7 Response</li>
                      <li>Seasonal Contracts</li>
                      <li>Snow & Weather Monitoring </li>
                      <li>Insured for Snow Services</li>
                      <li>Warehousing/ Commercial</li>
                      <li>Residential Homes & Strata</li>
                    </ul>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </section>
      {/* -------------- END OF SERVICES -------------- */}

      {/* -------------- TESTIMONIALS --------------   */}
      <section id="testimonial" className="testimonials">
        <div className="container">
          <h2
            className="section-header"
            data-aos="zoom-in"
            data-aos-duration="1500"
          >
            WHAT OUR CLIENTS ARE SAYING?{" "}
          </h2>
          <div className="text-center mb-5">
            <a
              href="https://www.google.com/search?q=burhan&oq=burhan&gs_lcrp=EgZjaHJvbWUqBggAEEUYOzIGCAAQRRg7MgYIARBFGEAyDQgCEC4YrwEYxwEYgAQyBwgDEC4YgAQyDQgEEC4YgwEYsQMYgAQyBwgFEC4YgAQyBwgGEAAYgAQyBggHEEUYPNIBCDIyNjRqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8#"
              className="testimonial-button"
            >
              Google Reviews
            </a>
            <a
              href="https://homestars.com/companies/198943-bur-han-garden-lawn-care?utm_source=google&utm_medium=cpc&utm_campaign=b2c-unbranded-sn-west-vancouver-dsa-2023&keyword=&matchtype=&utm_term=&utm_content=650228789924_c_&gad_source=1&gclid=CjwKCAiAuNGuBhAkEiwAGId4ats_5fYuQPc5zcARrhPSCu5Tj_DOd2epYn-qfcpsKJmmdQAgu5mrdRoCgDYQAvD_BwE"
              className="testimonial-button"
            >
              HomeStars Reviews
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <OwlCarousel
              id="customer-testimonoals"
              className="owl-carousel owl-theme"
              {...options}
            >
              {testiMonials.map((testiMonialDetail) => {
                return (
                  <TestiMonialsDetails
                    testiMonialDetail={testiMonialDetail}
                    key={testiMonialDetail._key}
                  />
                );
              })}
            </OwlCarousel>
          </div>
        </div>
      </section>
      {/* -------------- END OF TESTIMONIALS -------------*/}

      {/* ----------------- VALUES ----------------- */}
      <section className="ourValues ">
        <h2 className="section-header my-5" data-aos="zoom-in">
          OUR VALUES
        </h2>
        <div className="row">
          <div
            className="col-lg-3 col-md-6 col-sm-6 col-xs-12"
            data-aos="fade-right"
          >
            <div className="content">
              <img src={one} style={{ width: "4.3rem" }} alt="number 1" />
              <h3>Community</h3>
              <p>- We care about nature</p>
              <p>- We care about each other</p>
              <p>- We care about our community</p>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-6 col-xs-12"
            data-aos="zoom-in"
          >
            <div className="content ">
              <img src={two} style={{ width: "4.3rem" }} alt="number 2" />
              <h3>Passion</h3>
              <p>- We have fun together</p>
              <p>- We recognize and celebrate our successes</p>
              <p>- We focus on the details and take pride in our work </p>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-6 col-xs-12"
            data-aos="fade-left"
          >
            <div className="content">
              <img src={three} style={{ width: "4.3rem" }} alt="number 3" />
              <h3>Progressive</h3>
              <p>- We are collaborative and resourceful</p>
              <p>- We are focused on continuous improvement </p>
              <p>- We are committed to learning and development</p>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-6 col-xs-12"
            data-aos-delay="300"
            data-aos="fade-right"
          >
            <div className="content">
              <img src={four} style={{ width: "4.3rem" }} alt="number 4" />
              <h3>Integrity</h3>
              <p>- We are honest in our dealings </p>
              <p>- We are accountable and do what we say</p>
              <p>- We treat others and the environment with respect</p>
            </div>
          </div>
        </div>
      </section>
      {/* -------------- END OF VALUES -------------*/}

      {/* -------------- CONTACT --------------Í */}
      <section id="contact" className="contact">
        <div className="container">
          <h2
            className="section-header whiteDash"
            data-aos="zoom-in"
            data-aos-duration="1500"
            style={{ color: "var(--white-color)" }}
          >
            Contact
          </h2>
          <div className="row" data-aos="fade-up" data-aos-duration="1500">
            <div className="col-lg-6">
              <div className="info-box mb-4">
                <i class="fa-solid fa-location-dot"></i>
                <h3>Our Address</h3>
                <p>
                  Mailing Address : Suite #314 1641 Lonsdale Ave, North
                  Vancouver, BC, V7M 2J5
                </p>
                <p>
                  Shop Address : 2110 Front St, North Vancouver, BC, V7H 1A3
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="info-box mb-4">
                <i className="fa-solid fa-envelope"></i>
                <h3>Email Us</h3>
                <a
                  href="mailto:gardenandlawncare@bur-han.ca"
                  style={{ textDecoration: "none" }}
                >
                  <p>gardenandlawncare@bur-han.ca</p>
                </a>
                <br />
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="info-box mb-4">
                <i className="fa-solid fa-phone"></i>
                <h3>Call Us</h3>
                <a href="tel:604-983-2687" style={{ textDecoration: "none" }}>
                  <p>(604) 983-2687</p>
                </a>
                <br />
              </div>
            </div>
          </div>
          <div className="row" data-aos="fade-up" data-aos-duration="2500">
            <div className="col-lg-6 ">
              <iframe
                style={{ height: "100%", width: "100%", border: "0" }}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2601.4161203935946!2d-123.01846222309891!3d49.30640276907365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548670f2acf0caf5%3A0xa180b9dfb101ef53!2sBUR-HAN%20Garden%20%26%20Lawn%20Care!5e0!3m2!1sen!2sca!4v1707501402244!5m2!1sen!2sca"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                allowFullScreen
              ></iframe>{" "}
            </div>
            <div className="col-lg-6">
              <form ref={form} onSubmit={sendEmail} className="php-email-form">
                <div className="row">
                  <div className="col-md-6 form-group mb-3">
                    <input
                      type="text"
                      name="firstName"
                      className="form-control"
                      id="firstName"
                      placeholder="First Name *"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mb-3">
                    <input
                      type="text"
                      name="lastName"
                      className="form-control"
                      id="lastName"
                      placeholder="Last Name *"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mb-3">
                    <input
                      type="text"
                      name="phone"
                      className="form-control"
                      id="phone"
                      placeholder="Phone Number *"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mb-3">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Email *"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="address"
                      id="address"
                      placeholder="Address *"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="postalcode"
                      id="postalcode"
                      placeholder="PostalCode *"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mb-3">
                    <Select
                      id="select-city"
                      closeMenuOnSelect={true}
                      classNamePrefix="select-city"
                      className="select-city"
                      name="city"
                      placeholder="City *"
                      options={selectCity}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      styles={styles}
                      isSearchable={false}
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mb-3">
                    <Select
                      id="services"
                      isMulti
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      classNamePrefix="select"
                      className="select"
                      name="services"
                      placeholder="Services *"
                      options={servicesOption}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      styles={styles}
                      isSearchable={false}
                      required
                    />
                  </div>
                  <div className="col-md-12 form-group mb-3">
                    <Select
                      id="hearaboutus"
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      classNamePrefix="select"
                      className="select"
                      name="hearaboutus"
                      placeholder="How did you hear about us? *"
                      options={hearaboutusOption}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      styles={styles}
                      isSearchable={false}
                      required
                    />
                  </div>
                </div>
                <textarea
                  id="requiredservices"
                  name="requiredservices"
                  className="col-md-12 form-group mb-3 form-control"
                  placeholder="Required Services *"
                  rows="4"
                  required
                />
                <div className="text-center">
                  <button type="submit" name="submit" id="submit-home">
                    Get A Quote
                  </button>
                </div>
              </form>

              {/* Popup form message */}
              {showSuccessMessage && (
                <div className="popup active">
                  <div className="popup-content">
                    <i
                      className="fa fa-check main-content__checkmark"
                      id="checkmark"
                    ></i>
                    <h2>Thank you for contacting us!</h2>
                    <h3>
                      We'll get back to you as soon as possible. <br />
                      Follow us on Social:
                    </h3>
                    <div className="social">
                      <a
                        href="https://www.facebook.com/bhgardenandlawncare"
                        className="social d-flex align-items-center"
                      >
                        <i className="bi bi-facebook"></i>
                      </a>
                      <a
                        href="https://www.instagram.com/bhgardenandlawncare"
                        className="social d-flex align-items-center"
                      >
                        <i className="bi bi-instagram"></i>
                      </a>
                      <a
                        href="https://www.youtube.com/user/readyforvideo"
                        className="social d-flex align-items-center"
                      >
                        <i className="bi bi-youtube"></i>
                      </a>
                      <a
                        href="https://www.pinterest.ca/bhgardenandlawn"
                        className="social d-flex align-items-center"
                      >
                        <i className="bi bi-pinterest"></i>
                      </a>
                    </div>
                    <button onClick={handleReload}>
                      <span>Back to the Homepage </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* -------------- END OF CONTACT -------------*/}

      {/* FOOTER */}
      <Footer />
    </div>
  );
}
