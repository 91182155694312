import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import "../styleAllBlogs.css";
import img from "../../../assets/img/blog-chaferbeetle.png";
import img1 from "../../../assets/img/blog-chaferbeetle1.jpg";
import img2 from "../../../assets/img/blog-chaferbeetle2.jpg";
import img3 from "../../../assets/img/grub-gone0.png";
import img4 from "../../../assets/img/blog-chaferbeetle4.jpg";

export default function ChaferBeetle() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [pageType, setPageType] = useState("");

  //Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca/blog/chafer-beetle`;
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", canonicalUrl);

    const newTitle =
      "The Chafer Beetle, What do you need to know Blog";
    setPageTitle(newTitle);
    document.title = newTitle;

    const newDescription =
      "Learn everything about Chafer Beetle, its lifecycle, treatment, and damage repair in this comprehensive guide by BUR-HAN Garden & Lawn Care.";
    setPageDescription(newDescription);

    const newType = "article";
    setPageType(newType);

    const newKeywords = [
      "European Chafer Beetle, Chafer Beetle treatment, Chafer Beetle damage, Grub Gone, organic lawn care",
    ];
    setKeywords(newKeywords);
    document.keyword = newKeywords;

    // Update og:title meta tag
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", newTitle);

    // Update og:description meta tag
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", newDescription);

    // Update description meta tag
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", newDescription);

    // Update type meta tag
    document
      .querySelector('meta[property="og:type"]')
      .setAttribute("content", newType);

    // Update keywords meta tag
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", newKeywords.join(","));
  }, [location.pathname, setPageTitle, setPageDescription]);

  return (
    <div className="wrapper">
      <Header />
      <div className="beforeSection"></div>
      <div className="sectionBlogpost chafer">
        <a href="/blog" className="backPosts">
          <i className="bi bi-arrow-left"></i>
          Back
        </a>
        <article>
          <header>
            <h2>
              <div className="nth-line-1" data-aos="fade-up">
                The Chafer Beetle
              </div>
              <div className="nth-line-2" data-aos="fade-up">
                What you need to know
              </div>
            </h2>
            <p className="lead" data-aos="fade-right">
              Lifespan | Treatment | Damage Repair
            </p>
          </header>
          <hr />
          <section data-aos="fade-right">
            <p>
              <span>
                The European Chafer Beetle <br />
              </span>
              originated in continental Europe but can now be found in temperate
              climates across North America.
            </p>
            <p>
              <span>
                Lifespan:
                <br />
              </span>{" "}
              The life cycle of the Chafer Beetle lasts one year with new eggs
              hatching in July pending weather conditions. The eggs will lay
              dormant in the soil until there is enough moisture. With our
              summers in Vancouver and the Lower Mainland of Vancouver being
              unseasonably dry the last few years we are seeing the hatch of
              eggs pushed later. This is exhibited mostly in lawns with no
              irrigation systems or regular watering. New grubs then begin to
              feed on grass roots & damage to turf may start to become visible
              in brown, dying patches of grass. August - September grubs feed on
              the roots of turf and October - December grubs will continue to
              grow as they feed. They will remain within 5cm of the ground
              surface and predators may cause turf damage at this time as they
              dig up the grubs for food.
              <img
                src={img3}
                width={"100% !important"}
                alt="european chafer beetle"
                style={{ paddingTop: "4vw" }}
              />
              January - March the grubs will still continue to grow as thy feed
              and damage will continue. April - June is the period over which
              the grub will transform into a beetle. First flight is the term
              for when the beetles start emerging from the soil and look for
              mates. This usually occurs at the start of June in Vancouver. You
              will notice the occasional beetle. Peak flight is the term used to
              describe when the majority of the population moves out of the
              soil. At this time you may notice a steady emergence of the
              beetles from the soil and the beetles congregating in the air
              forming a swarm. Usually the swarm of flying beetles will move to
              the highest object near by, usually a tree. The beetles will mate
              and the females will then proceed to the surrounding turf grass
              areas. Females begin to deposit their eggs ( up to 50 per female)
              back into the soil. If the soil conditions have the correct amount
              of moisture the eggs will hatch within two weeks.
            </p>
            <img
              src={img1}
              width={"90% !important"}
              style={{ marginLeft: "1.5%" }}
              alt=""
            />
            <p>
              <span>Chafer Treatment: </span> <br />
              The best form of defence against the Chafer Beetle is to maintain
              a healthy lawn. This can be done by following the below steps:
              <br />
              <span>1. Lawn Cutting Height - </span>
              <br />
              cutting at a proper height of 2.5-3” on a weekly schedule will
              promote deeper root growth, making the turf more resistant to both
              the grubs feeding on the roots & the animals pulling it back.
              <br />
              <span>2. Lawn Fertilization - </span> <br />
              regular fertilization of your lawn will ensure that it receives
              all the proper nutrients it needs throughout the season - along
              with other applications such as Aeration, Limestone, Moss Etc.
              <br />
              <span>3. Lawn Irrigation - </span>
              <br />
              regular deep watering (2 times a week with 1-2 inches of water)
              will train the grass roots to grow deeper which helps the turf to
              become more resistant to attacks.
              <br />
              <span>4. GRUB GONE application - </span>
              <br /> every season will help protect your lawn from further
              damage. This product is organic but is still expected to be around
              90% effective so if done along with healthy maintenance, this will
              yield the best results.{" "}
            </p>

            <img
              src={img4}
              width={"85% !important"}
              style={{ marginLeft: "5%" }}
              alt="chafer beettle"
            />
            <p>
              <span>Application Cost: </span> <br />
              Call us today for a free estimate based on the size of your lawn.
            </p>

            <p>
              <span>Damage Information: </span> <br />
              If you have a serious Chafer Beetle infestation most of the turf
              damage will typically be caused by birds and animals such as
              raccoons or skunks digging up the chafer beetle grubs. The more
              compromised the root system of the lawn from the Chafer Beetles
              feeding on the roots, the easier these damaged areas will lift off
              by animals looking for food. Damage by animals is most severe in
              the Fall & Spring as this is when the grubs are rapidly increasing
              in size and feeding nearer to the surface. Every chafer beetle
              grub that gets eaten is one less beetle to lay another 50+ eggs
              come summer. The reason it is so easy for animals to roll back or
              destroy parts of the turf is due to the root system being
              compromised by the beetles themselves feeding on the turf roots.
              While you may wish to put down mesh or fencing in order to keep
              the lawn in place, if the roots have been eaten from within - you
              will still run into troubles for spring.{" "}
            </p>

            <p>
              <span>What is Grub Gone? </span> <br />
              Grub Gone is a brand NEW product aprroved for use in BC and is the
              first Bacillus thuringiensis galleriae(Btg) bio-insecticide to
              control turf grubs at a level that is equivalent to chemical
              standards but without the nasty side effects or off-target damage
              to beneficial insects,(such as bees and other pollinators). Unlike
              Nematodes, Grub Gone is effective against 1st, 2nd & 3rd instar
              pests and therefore can be as a curative treatment as well as a
              preventative one.
            </p>
            <p>
              <span>Effectiveness: </span> <br />
              This is an organic application, and as such will not be 100%
              guaranteed in completely killing all of the grubs in yourlawn.
              However, unlike Nematodes which only has a 60% success rate the
              first season and can only be applied in a 3 week window in the
              summer, Grub Gone is closer to 90% and can be applied more
              frequently.
            </p>

            <p>
              <span>Grub Gone Watering:</span>
              <br /> Grub Gone only requires one deep watering within a 72 hour
              window after the application has been completed in order to
              activate the product and achieve the best results. No longer will
              you need to water your lawn for a period of 2 weeks straight for
              it to work!
            </p>
            <p>
              <span>Chemical Control: </span> <br />
              In British Columbia the use of insecticides (such as Merit or
              Acelepryn) are completely prohibited for Residential use. These
              products are not environmentally friendly & can be unsafe for
              kids, pets, as well as toxic to beneficial insects, such as bees
              and other pollinators.{" "}
            </p>

            <p>
              <span>Contact Us: </span> <br />
              If you have any additional questions regarding Chafer beetle
              damage or would like additional information on the grub gone
              product, please do not hesitate to send us an email at{" "}
              <a
                href="mailto:gardenandlawncare@bur-han.ca"
                style={{
                  textDecoration: "none",
                  color: "var(--yellow-color)",
                  cursor: "pointer !important",
                }}
              >
                {" "}
                gardenandlawncare@bur-han.ca
              </a>{" "}
              or give our office a call at{" "}
              <a
                href="tel:604-983-2687"
                style={{
                  textDecoration: "none",
                  color: "var(--yellow-color)",
                  cursor: "pointer !important",
                }}
              >
                (604)983-2687
              </a>
              . Additionally, more information on the other types of lawn care services we offer such as applications, or information on how to fertilize your lawn can be found here: <a href="/services/residential-lawn-care" style={{color: "var(--yellow-color)",textDecoration: "none"}}>Residential Lawn Care </a>
              and <a href="/blog/the-essential-guide-to-lawn-fertilization" style={{color: "var(--yellow-color)",textDecoration: "none"}}>Fertilizer Blog</a>. 
            </p>
            <img
              src={img2}
              width={"90% !important"}
              style={{ marginLeft: "1.5%" }}
              alt="chafer beettle"
            />
            
          </section>
        </article>
        <div className="secondBanner">
          <div className="container" data-aos="fade-down">
            <div className="row">
              <div className="col-md-6">
                <h3>Ready to elevate your outdoor experience?</h3>
                <p>
                  Fill out our contact page, and let our expert landscaping
                  team bring your vision to life with a personalized estimate
                  tailored to your unique preferences and needs.
                </p>
              </div>
              <div className="col-md-6 ">
                <div className="call-to-action text-right">
                  <a href="/contact-us" className="get-a-quote">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
