import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useLocation } from "react-router-dom";
import img1 from "../../assets/img/image3.jpg";
import robert from "../../assets/img/robert1.jpeg";
import c1 from "../../assets/img/partners/badge2.png";
import c2 from "../../assets/img/partners/badge1.png";
import c3 from "../../assets/img/partners/badge3.png";
import c4 from "../../assets/img/partners/badge4.png";
import c5 from "../../assets/img/partners/badge5.png";
import c6 from "../../assets/img/partners/badge6.png";
import c7 from "../../assets/img/partners/badge7.png";
import c8 from "../../assets/img/partners/badge8.png";
import "./style.css";

export default function AboutUs() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [keywords, setKeywords] = useState([]);

  //Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca/about-us`;
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", canonicalUrl);

    const newTitle = "About Us | BUR-HAN Garden & Lawn Care";
    setPageTitle(newTitle);
    document.title = newTitle;

    const newDescription =
      "Learn about BUR-HAN Garden & Lawn Care, a leading provider of landscape maintenance and construction services in the Lower Mainland since 1995. Discover our commitment to excellence and honesty.";
    setPageDescription(newDescription);

    const newKeywords = [
      "Landscape maintenance,Landscape construction, Lawn care services,Landscaping services in Lower Mainland,Residential lawn care,Commercial landscaping,Strata landscaping services,Sustainable landscaping, Professional landscaping,Lawn maintenance services,Garden maintenance,Hardscape installation,Softscape installation,Landscape design,Irrigation management,Pest and disease control,Seasonal clean-ups"    ];
    setKeywords(newKeywords);
    document.keyword = newKeywords;

    // Update og:title meta tag
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", newTitle);

    // Update og:description meta tag
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", newDescription);

    // Update description meta tag
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", newDescription);

    // Update keywords meta tag
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", newKeywords.join(","));
  }, [location.pathname, setPageTitle, setPageDescription]);

  return (
    <div className="wrapper">
      <Header />
      <div className="aboutUsHeader">
        <h1 data-aos="zoom-in">About Us</h1>
      </div>
      <div className="aboutUsMain">
        {/* -------------- COMPANY -------------*/}
        <div id="company">
          <div className="container">
            <div className="row row-pb-md">
              <div className="col-md-10 col-sm-12 heading" data-aos="fade-up">
                <h2 className="mb-5">
                  "Greening your world, one lawn at a time."
                </h2>
                <p className="sub"></p>
                <p className="subtle-text" data-aos="zoom-in">
                  Since 1995
                </p>
              </div>
            </div>
            <div className="row cl">
              <div className="col-lg-6 col-sm-6 xs-6" data-aos="fade-right">
                <img src={img1} className="img-shadow fl" alt="BUR-HAN Team" />
              </div>
              <div
                className="col-lg-6 col-sm-6 xs-6 mb-4 hcresize "
                data-aos="fade-left"
              >
                <h3 className="heading-colored">Excellence & Honesty</h3>
                <p className="heading-colored-text">
                  BUR-HAN is a leading provider of landscape maintenance as well
                  as landscape construction services for residential, strata,
                  and commercial properties in the Lower Mainland. The company
                  has a fleet of 40+ trucks and a great team of well-trained,
                  hard working employees who are passionate about what they do.
                  With BUR-HAN as a partner, home owners as well as building
                  owners and property managers can gain the freedom and peace of
                  mind to focus their attention on other things. We believe that
                  our commitment to excellence and honesty sets us apart from
                  other landscaping companies. Contact us today to learn more
                  about what we can do for you.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* -------------- END OF COMPANY -------------*/}

        {/* -------------- FOUNDER -------------*/}
        <div id="company">
          <div className="container">
            <div className="row row-pb-md mlr">
              <div className="col-md-10 col-sm-12 heading " data-aos="fade-up">
                <h2>"Visionary leadership cultivating landscapes of beauty"</h2>
                <p className="sub"></p>
                <p
                  className="subtle-text"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  Our Founder
                </p>
              </div>
            </div>
            <div className="row cl mt-5">
              <div
                className="col-lg-6 col-sm-6 xs-6 mb-4 hcresize"
                data-aos="fade-up"
              >
                <h3 className="heading-colored-mirror">Robert Hannah</h3>
                <p className="heading-colored-text">
                  The company was established in 1995. The name “BUR-HAN” comes
                  from the last names of the two founders: Tim Bourke and Robert
                  Hannah. Tim and Robert worked together at an engineering firm
                  before deciding to start their own business. Forming BUR-HAN
                  was an opportunity to apply their expertise and excel in an
                  industry that needed organization and commitment to service.
                  Robert Hannah continues with BUR-HAN as the sole founder of
                  the company and General Manager growing the company since
                  2018.
                </p>
              </div>
              <div className="col-lg-6 col-sm-6 xs-6" data-aos="fade-left">
                <img
                  src={robert}
                  className="img-shadow fr mb-4"
                  alt="robert hannah,founder"
                />
              </div>
            </div>
          </div>
        </div>

        {/* -------------- END OF FOUNDER -------------*/}
        {/* -------------- BOX -------------*/}
        <div className="box">
          <h2>
            <span data-aos="zoom-in">
              "Transforming landscapes with excellence –
            </span>
            <div className="message">
              <div className="word">design</div>
              <div className="word">build</div>
              <div className="word">maintain</div>
            </div>
          </h2>
        </div>
        {/* -----------------  END BOX -----------------  */}

        {/* -------------- OUR SERVICES -------------*/}

        <div className="hexServices xpt-center">
          <h2 className="section-header" data-aos="fade-down">
            OUR SERVICES
          </h2>
          <div className="xpt">
            <div
              className="xpt-hex col-lg-4 col-md-6 col-sm-12"
              data-aos="fade-right"
            >
              <a href="/services/landscape-maintenance">
                <div className="xpt-ihex">
                  <div className="xpt-box-inr">
                    <h3 className="xpt-title xpt-center">
                      Landscape Maintenance
                    </h3>
                    <div className="hex__details">
                      <ul>
                        <li>- Lawn Maintenance</li>
                        <li>- Garden Maintenance</li>
                        <li>- Water bag installation and filling</li>
                        <li>- Irrigation Management</li>
                        <li>- Tree staking</li>
                        <li>- Pest and Disease Control</li>
                        <li>- Tree and Shrub care</li>
                        <li>- Seasonal Clean-ups</li>
                        <li>- Landscape Enhancement</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div
              className="xpt-hex col-lg-4 col-md-6 col-sm-12"
              data-aos="zoom-in"
            >
              <a href="/services/residential-lawn-care">
                <div className="xpt-ihex">
                  <div className="xpt-box-inr">
                    <h3 className="xpt-title xpt-center">
                      Residential Lawn Care
                    </h3>
                    <div className="hex__details">
                      <ul>
                        <li>- Weekly Lawn Mowing/Edging</li>
                        <li>- Fertilizing</li>
                        <li>- Liming</li>
                        <li>- Organic Weed Control Program</li>
                        <li>- Aeration</li>
                        <li>- Moss Control</li>
                        <li>- European Chafer Beetle Control</li>
                        <li>- Topdressing</li>
                        <li>- Overseeding</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div
              className="xpt-hex col-lg-4 col-md-6 col-sm-12"
              data-aos="fade-left"
            >
              <a href="/services/hardscape-installs-maintenance">
                <div className="xpt-ihex">
                  <div className="xpt-box-inr">
                    <h3 className="xpt-title xpt-center">
                      {" "}
                      Hardscape Installs/Maintenance{" "}
                    </h3>
                    <div className="hex__details">
                      <ul>
                        <li>- Paver/slab patios and walkways</li>
                        <li>- Modular block walls</li>
                        <li>
                          - New Irrigation Installations with New Landscapes
                        </li>
                        <li>- Grading and drainage solutions</li>
                        <li>- Gravel/river rock installation</li>
                        <li>- Landscape Lighting</li>
                        <li>
                          - Paver Maintenance Cleaning/Jointing/Levelling/Repair
                        </li>
                        <li>- Water features</li>
                        <li>- Excavation</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div
              className="xpt-hex col-lg-4 col-md-6 col-sm-12"
              data-aos="fade-right"
            >
              <a href="/services/softscape-installs-maintenance">
                <div className="xpt-ihex">
                  <div className="xpt-box-inr">
                    <h3 className="xpt-title xpt-center">
                      {" "}
                      Softscape Installs/Maintenance
                    </h3>
                    {/* <h5>(including pruning, shearing, hedging, mowing, weeding)</h5> */}
                    <div className="hex__details">
                      <ul>
                        <li>- 2D & 3D Design</li>
                        <li>
                          - Material installation: Mulch/Play-Chip/Rock/Stone
                        </li>
                        <li>
                          - Plant Selection & Installation
                          Annuals/Perennials/Trees/Shrubs/Hedges
                        </li>
                        <li>- Edging Installations: Garden Beds/Paths/Lawns</li>
                        <li>
                          - Garden Reclamation Cleanups/Overgrown
                          Blackberry+Bamboo Control
                        </li>
                        <li>
                          - Restoration Gardens/Native Plant Selection/Bi-law
                          Complaints
                        </li>
                        <li>- Pest and Disease Management</li>
                        <li>- Garden Care and Maintenance</li>
                        <li>- Soil Analysis & Amendment </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div
              className="xpt-hex col-lg-4 col-md-6 col-sm-12"
              data-aos="fade-up"
            >
              <a href="/services/design-and-modelling">
                <div className="xpt-ihex">
                  <div className="xpt-box-inr">
                    <h3 className="xpt-title xpt-center">
                      {" "}
                      Design And Modelling{" "}
                    </h3>
                    <div className="hex__details">
                      <ul>
                        <li>- Consultation & Site Analysis </li>
                        <li>- 2D & 3D Design</li>
                        <li>- Plant Selection</li>
                        <li>- Hardscaping</li>
                        <li>- Landscape Lighting</li>
                        <li>- Irrigation</li>
                        <li>
                          - Construction Documentation & Project Management
                        </li>
                        <li>- Maintenance Plans</li>
                        <li>- Permit Assistance</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div
              className="xpt-hex col-lg-4 col-md-6 col-sm-12"
              data-aos="fade-left"
            >
              <a href="/services/snow-management">
                <div className="xpt-ihex">
                  <div className="xpt-box-inr">
                    <h3 className="xpt-title xpt-center"> Snow Management</h3>
                    <div className="hex__details">
                      <ul>
                        <li>- Snow Plowing</li>
                        <li>- Snow Shovelling & Snow Blowing</li>
                        <li>- Salting, Pre & Post Treatments</li>
                        <li>- 24/7 Response</li>
                        <li>- Seasonal Contracts</li>
                        <li>- Snow & Weather Monitoring </li>
                        <li>- Insured for Snow Services</li>
                        <li>- Warehousing/ Commercial</li>
                        <li>- Residential Homes & Strata</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        {/* -----------------  PARTNERS -----------------  */}

        <section id="partners" className="partners">
          <div className="container-partners">
            <div className="row d-flex align-items-center justify-content-center">
              <div className="partners-section-heading">
                <h3 data-aos="fade-down">BUR-HAN Garden & Lawn Care</h3>
                <h2 className="section-header" data-aos="fade-down">
                  Awards and Affiliations
                </h2>
              </div>
              <div className="col-1-5">
                <img
                  src={c1}
                  className="img-fluid partners-img partner1"
                  alt="reader's choice winner badge"
                />
              </div>
              <div className="col-1-5">
                <img
                  src={c2}
                  className="img-fluid partners-img partner2"
                  alt=""
                />
              </div>
              <div className="col-1-5">
                <img
                  src={c3}
                  className="img-fluid partners-img partner3"
                  alt=""
                />
              </div>
              <div className="col-1-5">
                <img
                  src={c4}
                  className="img-fluid partners-img partner4"
                  alt=""
                />
              </div>
              <div className="col-1-5">
                <img
                  src={c5}
                  className="img-fluid partners-img partner5"
                  alt=""
                />
              </div>
              <div className="col-1-5">
                <img
                  src={c6}
                  className="img-fluid partners-img partner6"
                  alt=""
                />
              </div>
              <div className="col-1-5">
                <img
                  src={c7}
                  className="img-fluid partners-img partner7"
                  alt=""
                />
              </div>
              <div className="col-1-5">
                <img
                  src={c8}
                  className="img-fluid partners-img partner8"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        {/* -------------- END OF PARTNERS -------------*/}

        {/* -------------- WHY CHOOSE US -------------*/}
        <section id="whyChooseUs">
          <h2 className="section-header mx-auto" data-aos="fade-down">
            WHY CHOOSE US
          </h2>
          <div className="card-section">
            <div className="card" data-aos="fade-right">
              <div className="chooseUsIcons d-flex align-items-center justify-content-center mb-3">
                <i className="far fa-check-square checkmark "></i>
              </div>
              <h4>Expertise and Experience</h4>
              <p>
                We have over 28 years of experience in the industry and our team
                of professionals are highly skilled and knowledgeable in all
                aspects of landscaping. Our landscaping company is an industry
                leader due to our team's proficiency expertise and years of
                experience in the field.
              </p>
            </div>
            <div className="card" data-aos="fade-up">
              <div className="chooseUsIcons d-flex align-items-center justify-content-center mb-3">
                <i className="far fa-check-square checkmark "></i>
              </div>
              <h4>Attention to Sustainability</h4>
              <p>
                We are committed to environmentally friendly and sustainable
                landscaping practices. From using native plants to conserving
                water through efficient irrigation systems, we prioritize
                sustainability in all our projects. Our aim is to create
                beautiful landscapes that are also eco-friendly.
              </p>
            </div>
            <div className="card" data-aos="fade-left">
              <div className="chooseUsIcons d-flex align-items-center justify-content-center mb-3">
                <i className="far fa-check-square checkmark "></i>
              </div>
              <h4>Excellent Customer Service</h4>
              <p>
                Your satisfaction is our priority, and we strive to provide
                exceptional customer service at every step. Our friendly and
                professional staff are always ready to address any concerns or
                queries you may have. We work hard to ensure that you have a
                positive experience working with us.
              </p>
            </div>
            <div className="card" data-aos="fade-right">
              <div className="chooseUsIcons d-flex align-items-center justify-content-center mb-3">
                <i className="far fa-check-square checkmark "></i>
              </div>
              <h4>Quality Workmanship</h4>
              <p>
                We take pride in providing top-notch workmanship in all our
                landscaping projects. Whether it's designing a landscape,
                installing irrigation systems, or maintaining landscapes, our
                attention to detail ensures that the job is done right the first
                time.
              </p>
            </div>
            <div className="card" data-aos="fade-up">
              <div className="chooseUsIcons d-flex align-items-center justify-content-center mb-3">
                <i className="far fa-check-square checkmark "></i>
              </div>
              <h4>Customized Solutions</h4>
              <p c>
                We understand that every client has unique requirements and
                preferences. We offer personalized solutions tailored to meet
                your specific needs and vision. Our team works closely with you
                to create landscape designs and maintenance services that
                perfectly complements your property.
              </p>
            </div>
            <div className="card" data-aos="fade-left">
              <div className="chooseUsIcons d-flex align-items-center justify-content-center mb-3">
                <i className="far fa-check-square checkmark "></i>
              </div>
              <h4>Timely and Reliable</h4>
              <p>
                We value your time and understand the importance of meeting
                deadlines. When you choose us, you can rely on our team to
                complete the project within the agreed-upon timeframe. We
                believe in maintaining open communication to keep you informed
                about the progress of the work.
              </p>
            </div>
          </div>
        </section>

        {/* -------------- END OF WHY CHOOSE US -------------*/}
        {/* -------------- QUOTELINE-------------*/}
        <section id="quoteLine" className="quoteLine">
          <div className="content" data-aos="fade-down">
            <h3>
              Whether you need routine maintenance, a complete overhaul, or
              anything in between, we're here to turn your dreams into reality.
            </h3>
            <a href="/contact-us" className="buttonQuoteLine">
              GET A QUOTE
            </a>
          </div>
        </section>
        {/* -------------- END OF QUOTELINE -------------*/}

        <Footer />
      </div>
    </div>
  );
}
