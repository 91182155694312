import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./style.css";
import { useLocation } from "react-router-dom";
import img1 from "../../assets/img/image1.jpg";
import img2 from "../../assets/img/image2.jpg";
import img3 from "../../assets/img/image3.jpg";
import img4 from "../../assets/img/image6.jpg";
import img5 from "../../assets/img/image7.jpg";
import img6 from "../../assets/img/bh1.jpeg";

export default function JoinOurTeam() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [keywords, setKeywords] = useState([]);

  //Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca/join-our-team`;
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", canonicalUrl);

    const newTitle = "Join Our Team | BUR-HAN Garden & Lawn Care";
    setPageTitle(newTitle);
    document.title = newTitle;

    const newDescription =
      "Discover why it would be great to work with BUR-HAN Garden & Lawn Care. Explore exciting opportunities for professional development, recognition, and rewards. Join our inclusive culture and make an impact with meaningful work. Email your resume to careers@bur-han.ca and take the first step towards a rewarding career.";
    setPageDescription(newDescription);

    const newKeywords = [
      "join our team, BUR-HAN Garden and Lawn Care, career opportunities, professional development, recognition and rewards, diversity and inclusion, inclusive culture, perks, impactful work, work-life balance, empowering environment, innovation, collaborative excellence, resume submission, careers at BUR-HAN",
    ];
    setKeywords(newKeywords);
    document.keyword = newKeywords;

    // Update og:title meta tag
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", newTitle);

    // Update og:description meta tag
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", newDescription);

    // Update description meta tag
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", newDescription);

    // Update keywords meta tag
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", newKeywords.join(","));
  }, [location.pathname, setPageTitle, setPageDescription]);

  return (
    <div className="wrapper">
      <Header />
      <div className="teamHeader">
        <h1>Join Our Team</h1>
      </div>
      <div className="teamMain">
        <h2>Why it would be great to work with us?</h2>
        <hr />
         <p>
       <a className="careersLink" href="mailto:hr@bur-han.ca"> Email us your resume to: hr@bur-han.ca</a></p>
        <div className="container1">
          
          <div className="card-left" data-aos="fade-right">
            <div className="card-image sm">
              <img src={img1} alt="" />
            </div>
            <div className="card-text">
              <p>
                <span style={{ color: "blue" }}>FUTURE OPPORTUNITIES:</span>{" "}
                Joining the <span style={{ color: "blue" }}> BUR-HAN </span>{" "}
                team opens the door to a world of exciting opportunities and
                meaningful growth. Here's why becoming a part of our dynamic
                community is a decision you won't regret. Your journey with{" "}
                <span style={{ color: "blue" }}>BUR-HAN</span> is more than a
                job; it's an investment in your future. By joining our team,
                you're not only becoming a part of something bigger – you're
                becoming a vital contributor to our shared success.
              </p>
            </div>
          </div>
          <div className="card-top" data-aos="fade-up">
            <div className="card-image">
              <img src={img2} alt="" />
            </div>
            <div className="card-text">
              <p>
                <span style={{ color: "blue" }}>PROFESSIONAL DEVELOPMENT:</span>{" "}
                Your growth matters to us. Through ongoing training, mentorship,
                and access to resources, you'll have the chance to enhance your
                skills and advance your career in ways you never thought
                possible.
              </p>
            </div>
          </div>
          <div className="card-right" data-aos="fade-up">
            <div className="card-image">
              <img src={img5} alt="" />
            </div>
            <div className="card-text">
              <p>
                <span style={{ color: "blue" }}>RECOGNITION AND REWARDS:</span>{" "}
                Your hard work doesn't go unnoticed. We recognize and reward
                exceptional contributions, providing you with the acknowledgment
                you deserve.{" "}
              </p>
            </div>
          </div>
          <div className="card-bottom" data-aos="fade-left">
            <div className="card-image">
              <img src={img3} alt="" />
            </div>
            <div className="card-text">
              <p>
                <span style={{ color: "blue" }}>DIVERSITY AND INCLUSION: </span>
                We celebrate diversity and believe that a variety of
                perspectives enriches our work. By joining us, you become a
                vital part of our inclusive family, where every voice matters.
              </p>
            </div>
          </div>
          <div className="card-bottom" data-aos="fade-right">
            <div className="card-image">
              <img src={img4} alt="" />
            </div>
            <div className="card-text">
              <p>
                <span style={{ color: "blue" }}>INCLUSIVE CULTURE:</span> Our
                culture is built on respect, empathy, and mutual support. You'll
                find a friendly and inclusive atmosphere that values each
                individual for who they are.
              </p>
            </div>
          </div>
          <div className="card-middle" data-aos="fade-up">
            <div className="card-text">
              <p>
                <span style={{ color: "blue" }}>PERKS:</span> Enjoy monthly
                BBQ's, customized apparel, health benefits, RRSP contributions
                and so much more when you join the BUR-HAN Team! Our team is
                like our family, and we take care of our own and want to ensure
                everyone who works at BUR-HAN feels respected and appreciated
                for all the hard work that they do!
              </p>
            </div>
          </div>
          <div className="card-right" data-aos="fade-left">
            <div className="card-image">
              <img src={img5} alt="" />
            </div>
            <div className="card-text">
              <p>
                <span style={{ color: "blue" }}>IMPACTFUL WORK:</span> Join us
                in making a difference. Your work will have a tangible impact on
                our projects, clients, and the community at large, allowing you
                to see the direct result of your efforts.
              </p>
            </div>
          </div>
          <div className="card-left" data-aos="fade-up">
            <div className="card-image">
              <img src={img6} alt="" />
            </div>
            <div className="card-text">
              <p>
                <span style={{ color: "blue" }}> WORK-LIFE BALANCE:</span> We
                believe that a healthy work-life balance is essential for
                sustained success. Our flexible work arrangements and supportive
                culture ensure that you can excel in both your professional and
                personal pursuits.
              </p>
            </div>
          </div>
          <div className="card-bottom" data-aos="fade-left">
            <div className="card-image">
              <img src={img1} alt="" />
            </div>
            <div className="card-text">
              <p>
                <span style={{ color: "blue" }}> EMPOWERING ENVIRONMENT:</span>{" "}
                We empower our team members to take ownership of their work.
                You'll have the autonomy to make decisions and contribute your
                best, leading to a sense of accomplishment and pride.
              </p>
            </div>
          </div>
          <div className="card-right" data-aos="fade-right">
            <div className="card-image">
              <img src={img3} alt="" />
            </div>
            <div className="card-text">
              <p>
                <span style={{ color: "blue" }}>INNOVATION AT HEART: </span>
                We're at the forefront of innovation, and your contributions
                will play a pivotal role in shaping the future. Your ideas will
                be heard, valued, and transformed into reality.{" "}
              </p>
            </div>
          </div>
          <div className="card-middle" data-aos="fade-up">
            <div className="card-text">
              <p>
                <span style={{ color: "blue" }}>COLLABORATIVE EXCELLENCE:</span>{" "}
                We foster an environment where collaboration thrives. Working
                with talented individuals from diverse backgrounds, you'll be a
                part of a team that values your unique perspectives and
                encourages the exchange of ideas.
              </p>
            </div>
          </div>
        </div>
   
        <Footer />
      </div>
    </div>
  );
}
