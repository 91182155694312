import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./style.css";
import { useLocation } from "react-router-dom";

export default function Projects() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [keywords, setKeywords] = useState([]);

  //Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca/our-projects`;
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", canonicalUrl);

    const newTitle = "Our Projects | BUR-HAN Garden & Lawn Care";
    setPageTitle(newTitle);
    document.title = newTitle;

    const newDescription =
      "Explore our landscaping projects and discover how we can elevate your outdoor experience. From 3D design and paving stones to retaining walls, lawn care, plantings, and one-time cleanups, we offer a range of services to transform your outdoor space. Get a personalized quote today!";
    setPageDescription(newDescription);

    const newKeywords = [
      "landscaping projects, 3D design landscaping, paving stones, retaining walls service, lawn care, plantings, one-time cleanups, outdoor experience, landscaping services, personalized quote",
    ];
    setKeywords(newKeywords);
    document.keyword = newKeywords;

    // Update og:title meta tag
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", newTitle);

    // Update og:description meta tag
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", newDescription);

    // Update description meta tag
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", newDescription);

    // Update keywords meta tag
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", newKeywords.join(","));
  }, [location.pathname, setPageTitle, setPageDescription]);

  return (
    <div className="wrapper">
      <Header />
      <div className="projectsHeader">
        <h1 data-aos="zoom-in"> Projects</h1>
      </div>
      <div className="projectsMain" data-aos="fade-up">
        <h2 data-aos="fade-down">
          We are a team of passionate individuals who want to help you
        </h2>
        <hr />

        {/* -------------- background image box -------------*/}
        <div className="projectsBox">
          <div className="containerrow">
            <div className="row">
              <div className="col-4" data-aos="fade-right">
                <a href="/our-projects/3d-design">
                  <div className="box box1">
                    <h4> 3D Design</h4>
                  </div>
                </a>
              </div>
              <div className="col-4" data-aos="fade-down">
                <a href="/our-projects/paving-stones">
                  <div className="box box2">
                    <h4>Paving Stones</h4>
                  </div>
                </a>
              </div>
              <div className="col-4" data-aos="fade-up">
                <a href="/our-projects/retaining-walls">
                  <div className="box box3">
                    <h4> Retaining Walls</h4>
                  </div>
                </a>
              </div>
              <div className="col-4" data-aos="fade-left">
                <a href="/our-projects/lawn-care">
                  <div className="box box4">
                    <h4> Lawn Care</h4>
                  </div>
                </a>
              </div>
              <div className="col-4" data-aos="fade-up">
                <a href="/our-projects/plantings">
                  <div className="box box6">
                    <h4>Plantings</h4>
                  </div>
                </a>
              </div>
              <div className="col-4" data-aos="fade-right">
                <a href="/our-projects/one-time-cleanups">
                  <div className="box box5">
                    <h4>One-Time Cleanups</h4>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="secondBanner">
          <div className="container" data-aos="fade-down">
            <div className="row">
              <div className="col-md-6 ">
                <h3>Ready to elevate your outdoor experience?</h3>
                <p>
                  Fill out our Get a Quote page, and let our expert landscaping
                  team bring your vision to life with a personalized estimate
                  tailored to your unique preferences and needs.
                </p>
              </div>
              <div className="col-md-6 ">
                <div className="call-to-action text-right">
                  <a href="/contact-us" className="get-a-quote">
                    Get A Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* -------------- end -------------*/}

        <Footer />
      </div>
    </div>
  );
}
